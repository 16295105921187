import embedService from "../services/embed.service";

const embedModule = {
  namespaced: true,
  state: () => ({
    parsedInfo: {
      source: "",
      url: "",
      title: "",
      publishDate: "",
      sumarry: "",
      image: "",
      uuid: "",
    },
    error: "",
    loading: false,
    maxWidth: "",
  }),
  getters: {
    getInfo(state) {
      return state.parsedInfo;
    },
    gerError(state) {
      return state.error;
    },
    getLoading(state) {
      return state.loading;
    },
    getMaxWidth(state) {
      return state.maxWidth;
    },
  },
  actions: {
    fetchData: ({ commit }, { url }) => {
      commit("setLoading", true);
      embedService
        .fetchInfo(url)
        .then((response) => {
          commit("parseInfor", response.data.data);
        })
        .catch((error) => {
          console.log(error);
          commit("setError", error.response.data.data);
          setTimeout(() => {
            commit("setError", "");
          }, 1000);
        })
        .finally(() => {
          commit("setLoading", false);
        });
    },

    setError({ commit }, { error }) {
      commit("setError", error);
      setTimeout(() => {
        commit("setError", "");
      }, 1000);
    },

    setMaxWidth({ commit }, { maxWidth }) {
      commit("setMaxWidth", maxWidth);
    },
  },
  mutations: {
    parseInfor(state, payload) {
      const date = payload.publish_date
        ? new Date(payload.publish_date * 1000)
        : null;
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ];

      const words =
        payload.text
          .split(" ")
          .slice(0, 25)
          .join(" ") + "...";
      state.parsedInfo = {
        source: payload.source_url.replace(/https?:\/\//, ""),
        url: payload.url,
        title: payload.title,
        publishDate: payload.publish_date
          ? months[date.getMonth()] +
            " " +
            date.getDate() +
            ", " +
            date.getFullYear()
          : null,
        sumarry: words,
        image: payload.top_image,
        uuid: payload.uuid,
      };
    },
    setError(state, error) {
      state.error = error;
    },
    setMaxWidth(state, maxWidth) {
      state.maxWidth = maxWidth;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
  },
};

export default embedModule;
