import Vue from "vue";
import VueRouter from "vue-router";

// import Auth from "@/layouts/Auth";
import Dashboard from "@/layouts/Dashboard";
// pages
// import Login from "@/pages/Login";
import Configure from "@/pages/Configure";

const routes = [
  // {
  //   path: "/auth",
  //   component: Auth,
  //   children: [
  //     {
  //       // UserProfile will be rendered inside User's <router-view>
  //       // when /user/:id/profile is matched
  //       path: "login",
  //       name: "login",
  //       component: Login,
  //       beforeEnter: (to, from, next) => {
  //         // ...
  //         if (localStorage.getItem("token") !== null) {
  //           next({ name: "configure" });
  //         }
  //         next();
  //       },
  //     },
  //   ],
  // },
  {
    path: "/dashboard",
    component: Dashboard,
    // beforeEnter: (to, from, next) => {
    //   // ...
    //   if (localStorage.getItem("token") === null) {
    //     next({ name: "login" });
    //   }
    //   next();
    // },

    children: [
      {
        path: "configure",
        name: "configure",
        component: Configure,
      },
    ],
  },
  { path: "/", redirect: "/dashboard/configure" },
];

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
