<template>
  <v-card class="mx-auto" :dark="dark">
    <a :href="info.url" target="_blank" class="link">
      <v-img
        class="white--text align-end"
        height="200px"
        :src="info.image || 'https://cdn.vuetifyjs.com/images/cards/docks.jpg'"
      >
        <v-card-title class="image-title">
          {{ info.title || "Top 10 Australian beaches" }}
        </v-card-title>
      </v-img>
      <v-card-subtitle class="pb-0" v-if="info.publishDate">
        {{ info.publishDate }}
      </v-card-subtitle>
      <v-card-text>
        <p
          class="mb-0"
          :class="{ 'text--primary': !dark, 'text--white': dark }"
        >
          {{ info.sumarry || "Top 10 Australian beaches placeholder sumarry" }}
        </p>
        <v-card-subtitle class="pb-0 pr-0 pl-0">
          {{ info.source || "example.com" }}
        </v-card-subtitle>
      </v-card-text>
    </a>
  </v-card>
</template>

<script>
import dedent from "dedent";
import { mapGetters } from "vuex";
import { WIDGET_URL } from "../../config/config";
export default {
  name: "Card1",
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("embed", {
      info: "getInfo",
      maxWidth: "getMaxWidth",
    }),
  },
  watch: {
    info() {
      this.generateTemplate();
    },
    dark() {
      this.generateTemplate();
    },
    maxWidth() {
      this.generateTemplate();
    },
  },

  methods: {
    generateTemplate() {
      const html = dedent`
      <div class="cardly-card cardly-card-1 ${
        this.dark ? "cardly-theme__dark" : "cardly-theme__light"
      }" ${this.maxWidth ? `data-max="${this.maxWidth}"` : ""} data-id="${
        this.info.uuid
      }">
        <a class="cardly__link" href="${this.info.url}" target="_blank">
          <div class="cardly__image">
            <div class="cardly__sizer" style="padding-bottom: 75%;"></div>
            <div class="cardly__cover"style='background-image: url("${
              this.info.image
            }");'></div>
            <h2 class="cardly__headline">${this.info.title}</h2>
          </div>
        ${
          this.info.publishDate
            ? `<p class="cardly__date">${this.info.publishDate}</p>`
            : ""
        }
        <p class="cardly__intro">${this.info.sumarry}</p>
        <p class="cardly__source">${this.info.source}</p>
        </a>
      </div>
      <script src="${WIDGET_URL}" type="text/javascript"><\/script>
      `;
      this.$emit("generateTemplate", this.info.uuid ? html : "");
    },
  },
  mounted() {
    this.generateTemplate();
  },
};
</script>

<style>
.image-title {
  background-image: linear-gradient(transparent, #000000a8);
}
.link {
  text-decoration: none;
}
.theme--light .headline {
  color: #292929;
}
.theme--dark .headline,
.text--white {
  color: #fff;
}
</style>