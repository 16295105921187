<template>
  <v-card class="mx-auto" :dark="dark">
    <a :href="info.url" target="_blank" class="link">
      <v-card-title class="headline">
        {{ info.title || "Top 10 Australian beaches" }}
      </v-card-title>
      <v-card-subtitle class="pb-0" v-if="info.publishDate">
        {{ info.publishDate }}
      </v-card-subtitle>
      <v-card-text>
        <p
          class="mb-0"
          :class="{ 'text--primary': !dark, 'text--white': dark }"
        >
          {{ info.sumarry || "Top 10 Australian beaches placeholder sumarry" }}
        </p>
        <v-card-subtitle class="pb-0 pr-0 pl-0">
          {{ info.source || "example.com" }}
        </v-card-subtitle>
      </v-card-text>
    </a>
  </v-card>
</template>

<script>
import dedent from "dedent";
import { mapGetters } from "vuex";
import { WIDGET_URL } from "../../config/config";
export default {
  name: "Card2",
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("embed", {
      info: "getInfo",
      maxWidth: "getMaxWidth",
    }),
  },
  watch: {
    info() {
      this.generateTemplate();
    },
    dark() {
      this.generateTemplate();
    },
    maxWidth() {
      this.generateTemplate();
    },
  },
  methods: {
    generateTemplate() {
      const html = dedent`
      <div class="cardly-card cardly-card-2 ${
        this.dark ? "cardly-theme__dark" : "cardly-theme__light"
      }" ${this.maxWidth ? `data-max="${this.maxWidth}"` : ""} data-id="${
        this.info.uuid
      }">
          <a class="cardly__link" href="${this.info.url}" target="_blank">
          <h2 class="cardly__headline">${this.info.title}</h2>
          ${
            this.info.publishDate
              ? `<p class="cardly__date">${this.info.publishDate}</p>`
              : ""
          }
          <p class="cardly__intro">${this.info.sumarry}</p>
          <p class="cardly__source">${this.info.source}</p>
          </a>
      </div>
      <script src="${WIDGET_URL}" type="text/javascript"><\/script>`;
      this.$emit("generateTemplate", this.info.uuid ? html : "");
    },
  },
  mounted() {
    this.generateTemplate();
  },
};
</script>

<style>
.link {
  text-decoration: none;
}
.theme--light .headline {
  color: #292929;
}
.theme--dark .headline,
.text--white {
  color: #fff;
}
</style>