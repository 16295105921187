
<template>
  <form @submit="fetchData">
    <v-text-field
      v-model="url"
      color="teal"
      class="url-input"
      placeholder="Enter a url..."
      loading
      solo
      clearable
      hide-details
      dark
    >
      <template v-slot:progress>
        <v-progress-linear
          v-if="!loading"
          color="teal"
          absolute
        ></v-progress-linear>
      </template>
    </v-text-field>
  </form>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "UrlFetcher",
  data: () => ({
    url: "",
  }),
  computed: {
    ...mapGetters("embed", {
      loading: "getLoading",
    }),
  },
  methods: {
    fetchData(event) {
      event.preventDefault();

      if (!this.loading) {
        const rgex = new RegExp(
          /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/
        );
        if (!this.url || !this.url.match(rgex)) {
          this.$store.dispatch("embed/setError", {
            error: "Please enter a valid url",
          });
        } else {
          this.$store.dispatch("embed/fetchData", {
            url: this.url,
          });
        }
      }
    },
  },
  mounted() {
    if (this.$route.query.target) {
      const url = atob(this.$route.query.target);
      const rgex = new RegExp(
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/
      );
      if (url.match(rgex)) {
        this.url = url;
        this.$store.dispatch("embed/fetchData", {
          url,
        });
      }
    }
  },
};
</script>

<style lang="scss">
@import "../styles/variables";
.url-input .v-input__slot {
  background: $transparent !important;
}
</style>