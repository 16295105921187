<template>
  <div>
    <v-container>
      <v-row>
        <v-col offset-md="2" md="8"> <UrlFetcher /> </v-col>
      </v-row>
      <v-row>
        <v-col>
          <Divider />
        </v-col>
      </v-row>
      <v-row>
        <v-col md="3" sm="12" offset-md="1">
          <Options
            @changeCard="updateCard"
            @changeColorTheme="updateColorTheme"
          />
        </v-col>
        <v-col md="7" sm="12">
          <Card1
            :dark="dark"
            v-if="card === 'card1'"
            @generateTemplate="fillTextArea"
          />

          <Card2
            :dark="dark"
            v-if="card === 'card2'"
            @generateTemplate="fillTextArea"
          />

          <Card3
            :dark="dark"
            v-if="card === 'card3'"
            @generateTemplate="fillTextArea"
          />

          <Card4
            :dark="dark"
            v-if="card === 'card4'"
            @generateTemplate="fillTextArea"
          />

          <Card5
            :dark="dark"
            v-if="card === 'card5'"
            @generateTemplate="fillTextArea"
          />

          <div v-if="card !== ''">
            <br />
            <codemirror
              ref="codeTemplate"
              :options="cmOptions"
              :value="template"
            >
            </codemirror>
            <v-btn text small @click="copyText" color="white">Copy Code</v-btn>
            <v-btn text small color="green" v-if="copied">Copied...</v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Divider from "@/components/Divider";
import UrlFetcher from "@/components/UrlFetcher";
import Options from "@/components/Options";
import Card1 from "@/components/cards/Card1";
import Card2 from "@/components/cards/Card2";
import Card3 from "@/components/cards/Card3";
import Card4 from "@/components/cards/Card4";
import Card5 from "@/components/cards/Card5";

import { codemirror } from "vue-codemirror";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/cobalt.css";
import "codemirror/mode/xml/xml.js";

export default {
  name: "Configure",
  components: {
    codemirror,
    UrlFetcher,
    Divider,
    Options,
    Card1,
    Card2,
    Card3,
    Card4,
    Card5,
  },
  computed: {},
  data: () => ({
    card: "card1",
    dark: true,
    template: "",
    copied: false,
    cmOptions: {
      tabSize: 4,
      styleActiveLine: true,
      lineNumbers: true,
      line: true,
      readOnly: true,
      mode: "text/html",
      theme: "cobalt",
    },
  }),

  methods: {
    updateCard(card) {
      this.card = card;
    },
    updateColorTheme(dark) {
      this.dark = dark;
    },
    fillTextArea(template) {
      this.template = template;
    },
    copyText() {
      this.copied = true;
      const el = document.createElement("textarea");
      el.value = this.template;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false;
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
      setTimeout(() => {
        this.copied = false;
      }, 500);
    },
  },
};
</script>

<style lang="scss">
</style>
