import axios from "axios";
import { BASE_URL } from "../config/config";

const apiClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "x-api-key": "tSQDnivDd676zH8mbZ0tf2uwAc7bHcdy6If6uwSP",
  },
});

class Embed {
  static fetchInfo = (url) => {
    return apiClient.post("widget-meta", { url });
  };
}

export default Embed;
