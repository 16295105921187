<template>
  <div>
    <h3>Card Options</h3>
    <v-container class="px-0">
      <!-- <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <img
            v-bind="attrs"
            v-on="on"
            src="../assets/card1.png"
            class="card-preview"
            @click="
              () => {
                setCard('card1');
              }
            "
          />
        </template>
        <span>Card option 1</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <img
            v-bind="attrs"
            v-on="on"
            src="../assets/card2.png"
            class="card-preview"
            @click="
              () => {
                setCard('card2');
              }
            "
          />
        </template>
        <span>Card option 2</span>
      </v-tooltip> -->
      <v-btn
        v-for="card in cards"
        :key="card.key"
        :outlined="currentCard !== card.key"
        :depressed="currentCard === card.key"
        class="mb-2"
        color="teal"
        dark
        block
        @click="
          () => {
            setCard(card.key);
          }
        "
      >
        {{ card.title }}
      </v-btn>
    </v-container>
    <v-container class="px-0">
      <!-- <v-text-field
        v-model="maxWidth"
        placeholder="Max width"
        solo
        hide-details
        suffix="PX"
        type="number"
      ></v-text-field> -->
      <v-subheader class="pl-0"> Max width: {{ maxWidth }}px </v-subheader>
      <v-slider
        v-model="maxWidth"
        color="teal"
        min="0"
        max="1000"
        dense
        hide-details=""
      ></v-slider>
    </v-container>
    <v-container class="px-0">
      <v-switch v-model="dark" label="Dark" color="teal"></v-switch>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Options",
  data: () => ({
    dark: true,
    maxWidth: "",
    cards: [
      { title: "Brave", key: "card1" },
      { title: "Classic", key: "card2" },
      { title: "Modern", key: "card3" },
      { title: "Modern 2.0", key: "card4" },
      { title: "Light", key: "card5" },
    ],
    currentCard: "card1",
  }),
  watch: {
    dark(value) {
      this.$emit("changeColorTheme", value);
    },
    maxWidth(value) {
      this.$store.dispatch("embed/setMaxWidth", { maxWidth: value });
    },
  },
  methods: {
    setCard(card) {
      this.currentCard = card;
      this.$emit("changeCard", card);
    },
  },
};
</script>

<style lang="scss">
@import "../styles/variables";

.v-input--selection-controls {
  margin-top: 0;
}
.card-preview {
  max-width: 100%;
  cursor: pointer;
}
.v-subheader,
.v-label {
  color: $text-primary !important;
}
</style>