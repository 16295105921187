<template>
  <div>
    <v-app-bar class="main-bar" color="deep-purple darken-2" dark fixed>
      <template v-slot:img="{ props }">
        <v-img v-bind="props" gradient="to right, #7E57C2 , #5C6BC0"></v-img>
      </template>
      <v-toolbar-title class="page-title">Cardly.cc</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-main class="main-content">
      <router-view></router-view>
      <v-snackbar v-model="snackbar" color="red darken-2" timeout="1000">
        {{ error }}
      </v-snackbar>
    </v-main>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Dashbaord",
  computed: {
    ...mapGetters("embed", {
      error: "gerError",
    }),
  },
  data: () => ({
    snackbar: false,
  }),
  watch: {
    error(value) {
      if (value) {
        this.snackbar = true;
      }
    },
  },
  created() {
    // if (!this.user) {
    //   this.$store.dispatch("auth/fetchUser");
    // }
  },
};
</script>

<style lang="scss">
@import "../styles/variables";

.page-title {
  font-weight: bold;
  font-size: 1.4rem;
}
@media (max-width: 600px) {
  .col {
    flex: 0 0 100% !important;
  }
}
</style>
